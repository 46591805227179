import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

enum FilterType {
  Contract, 
  Person,
  Type,
  DateRange, 
  Status //Only used by tabs and no chiclets
}

enum StatusOptions {
  Submitted,
  Rejected,
  Approved,
  All
}

type FilterOption = {
  key?: string 
  type: FilterType,
  display: string,
  value: any,
}

 
// Define a type for the slice state
interface FilterSlice {
    value: number
    filters: FilterOption[],
    status : StatusOptions
}
  
  // Define the initial state using that type
  const initialState: FilterSlice = {
    value: 0,
    filters: [],
    status: StatusOptions.Submitted
  }

  
  export const filterSlice = createSlice({
    name: 'filters',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: { 
      add: (state, action : PayloadAction<FilterOption>) => {
         let filters = [...state.filters, action.payload]

          state.filters = filters;


         console.log("Setting filters...")
      },
      remove: (state, action: PayloadAction<string>) => {
       
        let z = state.filters.filter((el) =>  el.key !== action.payload); 
        state.filters = z
      },
      setStatus: (state, action: PayloadAction<StatusOptions>) => {
        state.status = action.payload
      },
      // Use the PayloadAction type to declare the contents of `action.payload`
      incrementByAmount: (state, action: PayloadAction<number>) => {
        state.value += action.payload
      },
    },
  })
  
  export const { remove, add, incrementByAmount, setStatus } = filterSlice.actions
  export {FilterType, StatusOptions}
  export type {FilterOption}
  // Other code such as selectors can use the imported `RootState` type
  export const selectCount = (state: RootState) => state.counter.value
  
  export default filterSlice.reducer