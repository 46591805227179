import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {RootState} from './store';
import {supabase} from '../supabaseClient';
import { AuthChangeEvent, AuthError, Session } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';

interface UserSlice {
    userId: string,
    gotRole: boolean,
    name: string,
    //
    canExport : boolean,
    approveAll: boolean,
    approveSelf: boolean,
    editAll: boolean,
    editSelf: boolean,
    viewOwn: boolean,
    viewAll: boolean,
}

const initialState : UserSlice = {
    userId : '',
    gotRole: false,
    name: 'null',
    //
    canExport : false,
    approveAll: false,
    approveSelf: false,
    editAll: false,
    editSelf: false,
    viewOwn: false,
    viewAll: false
}


 
export const userSlice = createSlice({ 
    name: 'user',
    initialState,
    reducers: {
        authHandler : (state, action: PayloadAction<any>) => { 

            console.log(action.payload);
            state.canExport = action.payload.can_export ?? false;
            state.approveAll = action.payload.approve_all ?? false;
            state.approveSelf = action.payload.approve_own ?? false;
            state.editAll = action.payload.edit_all ?? false;
            state.editSelf = action.payload.edit_own ?? false;
            state.viewOwn = action.payload.view_own ?? false;
            state.viewAll = action.payload.view_all ?? false;
            state.name = action.payload.name ?? 'null';

            state.gotRole = true;
        }
    },
});

export const {authHandler} = userSlice.actions;

export default userSlice.reducer;