import NiceModal from '@ebay/nice-modal-react';
import { Badge, Button, Persona, Popover, PopoverSurface, PopoverTrigger, tokens} from '@fluentui/react-components';
import { Comment16Filled, Airplane24Filled, BriefcaseMedical24Filled, BriefcaseOff24Filled, Toolbox24Filled, Edit24Regular, Dismiss24Regular, CheckmarkFilled, ContentView24Filled, Open24Filled, Open20Regular, QuestionCircleRegular, QuestionCircleFilled } from '@fluentui/react-icons';
import { TagGroup, Tag } from '@fluentui/react-tags-preview';
import { ICellRendererParams } from 'ag-grid-enterprise';
import BonusSheetDialog from '../../dialogs/BonusSheetDialog';
import * as React from 'react';
import { useAppSelector } from '../../hooks';
import {ModalPresentationMode} from '../../dialogs/BonusSheetDialog';
const renderPersonaCell = (props: ICellRendererParams) => {
    const cellValue = props.value;
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center' }}>
            <Persona name={cellValue?.full_name} size="small" secondaryText={cellValue?.job_title} avatar={{ color: 'colorful' }} />
        </div>
    );
};

const renderDialogCellWrapper = (props: ICellRendererParams) => <RenderDialogCell {...props}/>

const RenderDialogCell = (props: ICellRendererParams) => {
    const { value } = props;
    const role = useAppSelector(s => s.user);
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'end' }}>
            {value?.comment &&
                <Popover positioning='below-end' size='small' withArrow>
                    <PopoverTrigger disableButtonEnhancement>
                        <Button icon={<Comment16Filled color={tokens.colorBrandForeground1} />} appearance='transparent'/>
                    </PopoverTrigger>

                    <PopoverSurface>
                        <div style={{ maxWidth: '200' }}>
                            <span>
                                {value?.comment}
                            </span>
                        </div>
                    </PopoverSurface>
                </Popover>}

                {role.approveAll &&  
                 <Button icon={<Open20Regular />}  appearance='transparent' onClick={() => NiceModal.show(BonusSheetDialog, {submissionId: value?.id, mode: ModalPresentationMode.Edit })} /> }
        </div>
    );
};


const statusChiclet = (props: ICellRendererParams) => {
    const {approved, rejected} = props.value;
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center' }}>
                 {approved && <Badge icon={<CheckmarkFilled />} appearance='tint' color={'success'} shape='rounded'>Approved</Badge>}
                {rejected && <Badge icon={<QuestionCircleFilled />} appearance='tint' color={'danger'} shape='rounded'>Rejected</Badge>}
                {!approved && !rejected && <Badge icon={<QuestionCircleFilled />} appearance='tint' color={'informative'} shape='rounded'>Pending</Badge> }
        </div>
    )
};

const renderBonusChiclets = (props: ICellRendererParams) => {
    const { attendance, subsistance, workingAway } = props.value;
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center' }}>
            <TagGroup>
                <Tag size="small" appearance={attendance ? 'brand' : 'outline'}>
                    Attendance
                </Tag>
                <Tag size="small" appearance={subsistance ? 'brand' : 'outline'}>
                    Subsistance
                </Tag>
                <Tag size="small" appearance={workingAway ? 'brand' : 'outline'}>
                    Working Away
                </Tag>
            </TagGroup>
        </div>
    )
}

const renderStatusIcons = (props: ICellRendererParams) => {
    const value = props.value;
    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center' }}>
                {value === 'work' && <Toolbox24Filled />}
                {value === 'leave' && <Airplane24Filled />}
                {value === 'sick' && <BriefcaseMedical24Filled />}
                {value === 'nowork' && <BriefcaseOff24Filled />}
          </div>
    )
}

export { renderPersonaCell, renderDialogCellWrapper, 
    renderBonusChiclets, renderStatusIcons, 
    statusChiclet }