import React, {useState} from 'react';
import { supabase } from '../../supabaseClient'
import { Combobox, ComboboxProps, Option, Persona} from '@fluentui/react-components'
import { debounce } from "lodash-es"
import { useController} from 'react-hook-form';

type PeopleResult = {
    id: any;
    job_title: any;
    full_name: any;
    profile: any;
}

const PeopleLookup = ({name, control, setValue} : any) => {
   const { field } = useController({ name,
        control,
        rules: { required: true },
    });

    const [candidates, setCandidates] = useState<PeopleResult[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [typeahead, setTypeahead] = useState<string | null>();

    const onChange: ComboboxProps["onChange"] = (event) => debouncedSearch(event.target.value);
      
    const debouncedSearch = React.useRef(debounce(async (criteria) => { await getResults(criteria)  }, 0) ).current;

    React.useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);


    const getResults = async (query : string) => {
        setLoading(true);
        setTypeahead(query);
        //TODO: Move this to FTS for perf
        const { data, error } = await supabase
            .from('people')
            .select('id, job_title, full_name, profile (*)')
            .limit(5)
            .ilike('full_name', `%${query}%`)
        setLoading(false);
        setCandidates(data ?? []); 
    }

    const onOptionSelect : ComboboxProps["onOptionSelect"] = (event, data) =>  {
        setTypeahead(null);
        field.onChange(candidates.find(c => c.id === data.optionValue))
    }
    
    return (
        <Combobox onChange={onChange} onOptionSelect={onOptionSelect} 
        name={field.name} onBlur={field.onBlur} selectedOptions={field?.value?.id}
        value={typeahead ? typeahead : field.value ? `${field.value?.full_name} (${field.value?.job_title})` : ''} 
        placeholder='Find a person...'>
            {loading && <Option disabled>Loading...</Option>}
            {!loading && candidates?.map(candidate => <Option value={candidate.id} text={candidate.full_name}>
                <Persona size='small'
                name={candidate.full_name}  
                avatar={{ color: "colorful" }}  
                secondaryText={candidate.job_title} /></Option>)}
            {!loading && candidates.length === 0 && <Option disabled>No results found</Option>}
        </Combobox>
    )
}

export {PeopleLookup}
 
