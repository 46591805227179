import { Menu, MenuTrigger, Button, MenuPopover, MenuList, MenuItem } from "@fluentui/react-components";
import { Toolbox24Regular, Airplane24Regular, BriefcaseMedical24Regular, BriefcaseOff24Regular } from "@fluentui/react-icons";
import { InteractionTag, InteractionTagPrimary } from "@fluentui/react-tags-preview";
import React from "react";
import { useController } from "react-hook-form";

const CustomStatusPicker = ({ control, name }: any) => {
    const { field } = useController({
        name,
        control,
        rules: { required: true },
    });

    const [value, setValue] = React.useState(field.value || []);

    const updateValue = (value: string) => {
        setValue(value);
        field.onChange(value)
    }
  
    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button appearance='outline' icon={
                    <>
                        {value === 'work' && <Toolbox24Regular />}
                        {value === 'leave' && <Airplane24Regular />}
                        {value === 'sick' && <BriefcaseMedical24Regular />}
                        {value === 'nowork' && <BriefcaseOff24Regular />}
                    </>
                } />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    <MenuItem icon={<Toolbox24Regular />} onClick={() => updateValue('work')}>At Work</MenuItem>
                    <MenuItem icon={<Airplane24Regular />} onClick={() => updateValue('leave')}>Annual Leave</MenuItem>
                    <MenuItem icon={<BriefcaseMedical24Regular />} onClick={() => updateValue('sick')}>Sick</MenuItem>
                    <MenuItem icon={<BriefcaseOff24Regular />} onClick={() => updateValue('nowork')}>Off work</MenuItem>

                </MenuList>
            </MenuPopover>
        </Menu>
    )
}

const CustomCheckboxChiclet = ({ control, name, children }: any) => {
    const { field } = useController({
        name,
        control,
    });

    const toggle = () => { 
        field.onChange(!field.value);
    }

    return (
        <InteractionTag onClick={toggle} size="small" appearance={field.value ? 'brand' : 'outline'}>
            <InteractionTagPrimary >
                {children}
            </InteractionTagPrimary>
        </InteractionTag>
    )
}


export {CustomStatusPicker, CustomCheckboxChiclet}