import { FluentProvider, tokens, BrandVariants, Theme,createLightTheme} from "@fluentui/react-components";
import { Provider } from "react-redux";
import { store } from '../store/store';
import NiceModal from "@ebay/nice-modal-react";
import { supabase } from "../supabaseClient";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import AppRouter from "./AppRouter";

export default function App() {
  
  const myNewTheme: BrandVariants = { 
    10: "#030403",
    20: "#151A19",
    30: "#212C2A",
    40: "#2A3935",
    50: "#324642",
    60: "#3B534E",
    70: "#45615B",
    80: "#4E7068",
    90: "#587E76",
    100: "#618D84",
    110: "#6D9C92",
    120: "#81A9A1",
    130: "#95B7AF",
    140: "#A9C5BE",
    150: "#BDD3CE",
    160: "#D2E1DD"
    };
    
     const lightTheme: Theme = {
       ...createLightTheme(myNewTheme), 
    };
    
    //const darkTheme: Theme = {
    //   ...createDarkTheme(myNewTheme), 
    //};

  return (
       <Provider store={store}>
        <SessionContextProvider supabaseClient={supabase}>
          <FluentProvider
            theme={lightTheme} style={{ background: tokens.colorNeutralBackground3 }} >
            <NiceModal.Provider>
              <AppRouter />
            </NiceModal.Provider>
          </FluentProvider>
        </SessionContextProvider>

      </Provider>
   );
}
