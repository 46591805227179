import React from "react";
import {Button, makeStyles, tokens} from '@fluentui/react-components';
import { ShiftsAdd24Regular} from '@fluentui/react-icons';
import NiceModal from "@ebay/nice-modal-react";
import MyPage from '../components/grid/Grid'
import BonusSheetDialog, {ModalPresentationMode} from "../dialogs/BonusSheetDialog";
import { FeedbackBar } from "../components/shell/FeedbackBar";
import { FilterComboBox } from "../components/sheet/FilterComboBox";
import { FilterChiclets } from "../components/tables/FilterChiclets";
import { StatusTabs } from "../components/StatusTabs";
 

const useStyles = makeStyles({
    root: {
        display: 'flex',
        height: '100vh',
        width: '100vw',
        flexDirection: 'column'
    },
    toolbar: {
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground2,
        height: "48px",
        justifyContent: 'space-between',
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
        boxShadow: tokens.shadow28
    },
    gridContainer: {
        display: 'flex',
        flexGrow: '1',
    },
    coloredCell : {
        backgroundColor: 'yellow'
    }
});


const SubmissionPage = () => {
 
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <FeedbackBar />            
            <div className={styles.toolbar}>
                <StatusTabs /> 
                <span>&nbsp;</span>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '0.5em' }}>
                    <FilterChiclets /> 
                    <FilterComboBox />
                    <Button appearance="primary" icon={<ShiftsAdd24Regular /> } 
                        onClick={() => NiceModal.show(BonusSheetDialog, {mode: ModalPresentationMode.New})}>Add Entry</Button>
                 </div>
            </div>
            <div className={styles.gridContainer}>
              <MyPage />
            </div>
        </div>
    )
}

export default SubmissionPage;