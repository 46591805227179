
const toTime = (value: number) => { 
    switch(value) {
        case 0: return '12:00 AM';
        case 0.5: return '12:30 AM';
        case 1: return '1:00 AM';
        case 1.5: return '1:30 AM';
        case 2: return '2:00 AM';
        case 2.5: return '2:30 AM';
        case 3: return '3:00 AM';
        case 3.5: return '3:30 AM';
        case 4: return '4:00 AM';
        case 4.5: return '4:30 AM';
        case 5: return '5:00 AM';
        case 5.5: return '5:30 AM';
        case 6: return '6:00 AM';
        case 6.5: return '6:30 AM';
        case 7: return '7:00 AM';
        case 7.5: return '7:30 AM';
        case 8: return '8:00 AM';
        case 8.5: return '8:30 AM';
        case 9: return '9:00 AM';
        case 9.5: return '9:30 AM';
        case 10: return '10:00 AM';
        case 10.5: return '10:30 AM';
        case 11: return '11:00 AM';
        case 11.5: return '11:30 AM';
        case 12: return '12:00 PM';
        case 12.5: return '12:30 PM';
        case 13: return '1:00 PM';
        case 13.5: return '1:30 PM';
        case 14: return '2:00 PM';
        case 14.5: return '2:30 PM';
        case 15: return '3:00 PM';
        case 15.5: return '3:30 PM';
        case 16: return '4:00 PM';
        case 16.5: return '4:30 PM';
        case 17: return '5:00 PM';
        case 17.5: return '5:30 PM';
        case 18: return '6:00 PM';
        case 18.5: return '6:30 PM';
        case 19: return '7:00 PM';
        case 19.5: return '7:30 PM';
        case 20: return '8:00 PM';
        case 20.5: return '8:30 PM';
        case 21: return '9:00 PM';
        case 21.5: return '9:30 PM';
        case 22: return '10:00 PM';
        case 22.5: return '10:30 PM';
        case 23: return '11:00 PM';
        case 23.5: return '11:30 PM';
        case 24: return '12:00 AM';
        default: return '-';
    }
}
 
const formatDate = (date : string) =>  toTime(parseFloat(date));

export {formatDate}