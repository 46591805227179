import { Avatar, Body1, Button, Caption1Strong, Field } from '@fluentui/react-components'
import * as React from 'react';
import { PeopleLookup } from '../components/sheet/PeopleLookup';
import { DismissRegular } from '@fluentui/react-icons';


const PeoplePicker = ({control, name, setValue, watch } : any) => {


    const onClick = () => { 
        setValue(name, null);
    }

    return (
        <Field label={"Person"}>
       <div style={{display: 'flex', flexDirection: 'row', columnGap: '0.25em'}}>
            {watch(name) &&
            <> 
            <Avatar color='colorful' name={watch("personname")}/>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <Body1>{watch(name)?.full_name}</Body1>
                <Caption1Strong>{watch(name)?.job_title}</Caption1Strong>
            </div>
            <span/>
            <Button icon={<DismissRegular />} appearance='subtle' onClick={onClick}/>
            </>
            }
        {!watch(name) && <PeopleLookup control={control} name={name} setValue={setValue} /> }
       </div>
       </Field>
    )
}

export {PeoplePicker}