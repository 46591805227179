/* eslint-disable react-hooks/rules-of-hooks */
 import { useEffect, useState } from 'react';
import { AgGridReact,  } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { makeStyles, tokens } from '@fluentui/react-components';
import 'ag-grid-enterprise';
import {supabase} from '../../supabaseClient';

import { renderPersonaCell, renderDialogCellWrapper, renderBonusChiclets, renderStatusIcons, statusChiclet } from './CellRenderers'
import { defaultColumns } from './GridColumnDefs';
import { useAppSelector } from '../../hooks';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { FilterType, StatusOptions } from '../../store/filterSlice';
import { GoTrueClient } from '@supabase/supabase-js';
import { array } from 'yup';

const formatDate = (date : string) =>  !date ? '-' : `${new Date(date)?.getHours().toString().padStart(2, '0')}:${new Date(date)?.getMinutes().toString().padStart(2, '0')}`
//const formatDate = (date : string) =>  !date ? '-' : `${new Date(date)?.toTimeString()}`

const MyPage = () => { 
    const [columnDefs] = useState(defaultColumns);

    const client = useSupabaseClient();

    const filters = useAppSelector(s => s.filters.filters);
    const statusFilter = useAppSelector(s  => s.filters.status);

    const [data, setData] = useState<any[]>([]);

    //type FilterOption = {
    //    key?: string 
     //   type: FilterType,
     //   display: string,
       // value: any,
     // }


    const loadDataDeferred = async () => { 
        const { data, error } = await supabase.auth.getSession()
        setData([]);
        let promise = client
            .from('entries_weekly')
            .select(`*,
            contract_sat:jobs!entries_weekly_sat_contract_fkey (id,job_number, contract_manager ),
            contract_sun:jobs!entries_weekly_sun_contract_fkey (id,job_number, contract_manager ),
            contract_mon:jobs!entries_weekly_mon_contract_fkey (id,job_number, contract_manager ),
            contract_tue:jobs!entries_weekly_tue_contract_fkey (id,job_number, contract_manager ),
            contract_wed:jobs!entries_weekly_wed_contract_fkey (id,job_number, contract_manager ),
            contract_thur:jobs!entries_weekly_thur_contract_fkey (id,job_number, contract_manager ),
            contract_fri:jobs!entries_weekly_fri_contract_fkey (id,job_number, contract_manager ),
            people (id, full_name, job_title),
            created_by:profiles!created_by (id, full_name)
            `).order('id', {ascending: false})
            .eq('isDeleted', false);


        console.log('Running Status filer: ' + statusFilter);

        if (statusFilter) {
            if (statusFilter == StatusOptions.Approved) {
                promise = promise.eq('approved', true);
            }
            // eslint-disable-next-line eqeqeq
            //@ts-ignore
            if (statusFilter == StatusOptions.Submitted) {
                promise = promise.eq('approved', false);
                promise = promise.eq('rejected', false);
            }
            if(statusFilter === StatusOptions.All) {
                promise = promise.eq('approved', false);
                promise = promise.eq('rejected', false);
            }
            if (statusFilter == StatusOptions.Rejected) {
                promise = promise.eq('rejected', true);
            }
        }


        if (filters?.length > 0) {
            let contractFilters = filters.filter(x => x.type == FilterType.Contract).map(x => x.value);
            console.log(contractFilters);
            if (contractFilters.length > 0) {
                let arrayString = `(${contractFilters.join(',')})`
                let string = `mon_contract.in.${arrayString}`
                string += `,tue_contract.in.${arrayString}`
                string += `,wed_contract.in.${arrayString}`
                string += `,thur_contract.in.${arrayString}`
                string += `,fri_contract.in.${arrayString}`
                string += `,sat_contract.in.${arrayString}`
                string += `,sun_contract.in.${arrayString}`
                console.log('Running Contract filer: ' + string);
                promise.or(string);
             }
            let peopleFilters = filters.filter(x => x.type == FilterType.Person).map(x => x.value);
            console.log(peopleFilters);
            if (peopleFilters.length > 0) {
                let arrayString = `(${peopleFilters.join(',')})`
                promise = promise.in('person_id', peopleFilters);
            }
        }

        var result = await promise;
        console.log(result.data);


        console.log('Redeem Promise and execute query');
        promise.then(({ data, error }) => { 
            console.log('data', data);
            setData(data ?? []);
        });
    } 

    //useEffect(() => { loadData() }, []);

    useEffect(() => {
        console.log(filters);
        loadDataDeferred();
    }, [filters, statusFilter]);

   return (
        <div className="ag-theme-alpine" style={{height: '100%', width: '100%'}}>
            <AgGridReact groupAllowUnbalanced={true}  
                rowData={data}
                //@ts-ignore
                columnDefs={columnDefs}>
            </AgGridReact>
        </div>
    );
}

export default MyPage;