import { makeStyles,Button, Body1, 
    Dialog, 
    DialogBody, DialogContent, DialogSurface, 
    DialogTitle, DialogTrigger, Subtitle2Stronger } from '@fluentui/react-components';
import * as React from 'react';
import { Dismiss24Regular, Info12Regular } from '@fluentui/react-icons';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
 
const useStyles = makeStyles({
    root: {
        display: 'flex',
        height: '52px',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '1em',
        paddingRight: '1em',
        width: '100vw',
        flexDirection: 'row',
        zIndex: 99,
        userSelect: 'none',
        backgroundColor: "#455663",
        color: 'white'
    },
});

const FeedbackModal =  NiceModal.create(() => {
    const modal = useModal();

    return <Dialog open={modal.visible}>
    <DialogSurface>
      <DialogBody style={{
         height: '90vh',
      }}>
        <DialogTitle
          action={
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
                onClick={() => modal.remove()}
              />
            </DialogTrigger>
          }> Feedback
        </DialogTitle>
        <DialogContent>
        <iframe title="Feedback Form" width="100%" height="100%" src="https://forms.microsoft.com/e/6Dh02ccutd?embed=true"    style={{border: 'none', maxWidth: '100%', 'maxHeight':'100vh'}}> </iframe>
        </DialogContent>
      </DialogBody>
    </DialogSurface>
  </Dialog>
});



const FeedbackBar = () => {

    const styles = useStyles();
 
    return (
        <div className={styles.root}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1em'}}>
                <img src="/logo.png" width={32} height={32} title="Logo" />
                <Subtitle2Stronger>Bonus Sheets</Subtitle2Stronger>
            </div>
            <div>
            <Body1>

                    <Info12Regular />&nbsp;
                    Having issues or got an idea? Let us know!
                </Body1>
                <Button appearance='subtle' style={{color: 'white'}} onClick={() => NiceModal.show(FeedbackModal, {})}>Give Feedback</Button>
            </div>
        </div>
    )
}

export {FeedbackBar}