import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog, DialogSurface, DialogBody, DialogTitle, 
  DialogTrigger, DialogContent, Button, DialogActions } from "@fluentui/react-components";
 
const DiscardModal =  NiceModal.create(() => {
    const modal = useModal();

    const resolve = (discard: boolean) => { 
        modal.resolve({discard});
        modal.remove();
    }

    return <Dialog open={modal.visible}>
       <DialogSurface>
        <DialogBody>
          <DialogTitle>Discard Changes?</DialogTitle>
          <DialogContent>
            You've made changes to this sheet. Do you want to discard your changes?
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => resolve(true)}>Discard</Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={() => resolve(false)}>Save changes</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
   </Dialog>
});


export default DiscardModal;