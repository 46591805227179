import * as React from 'react';
import { addDays, addHours, addMinutes } from 'date-fns';
import {supabase} from './supabaseClient';
import { useForm } from 'react-hook-form';

const TestPage = () => {
    const { register, handleSubmit, watch, control, reset,
        formState: { errors, isDirty, isValid },   getValues, setValue } = useForm({
            defaultValues: { 
                test: '2023-01-01T00:00:00.000Z'
            }
        });


    const addToDate = (date: Date, minutes: number) => addMinutes(date, minutes);

    const minuteFormatter = (value : number) =>`${Math.floor(value).toString().padStart(2, '0')}:${(value - Math.floor(value)).toString().padStart(2, '0')}:00.000`;
    
    const generateIsoString = (date : string, hours : number) => `${date}T${minuteFormatter}Z`;

    const onSubmit =  async (formData :any) => { 


        console.log(formData);




            const {error, data} = await supabase
                .from('DBTest')
                .insert(
                    {
                        Test: `${formData.test}T12:00:00.000Z`
                    }
                 );
            console.log(data);
            console.log(error);
    };


    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: '0.25em', padding: 100}}>
    <form onSubmit={handleSubmit(onSubmit)}>
            <h3>Test</h3>
            <hr />
            <input {...register('test')} type='date' />
            {watch('test')}
        <p>
            { watch('test') && addToDate(new Date(watch('test')), 1.5 * 60)?.toISOString()?.toLocaleString()}
            </p>
            <button onClick={() => {
                console.log(getValues());
            }}>Get Values</button>
            <span>{isDirty ? 'dirty' : 'clean'} </span>
            <span>{JSON.stringify(errors)}</span>


            <p>Test Formatter</p>

            


            <input type="submit" />

            </form>
        </div>
    )
}


export { TestPage }