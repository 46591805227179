import React, {useState} from 'react';
import { Caption1, Caption1Strong, Combobox, ComboboxProps, Option} from '@fluentui/react-components'
import { debounce } from "lodash-es"
import { useController } from 'react-hook-form';
import { useSupabaseClient } from '@supabase/auth-helpers-react';

type ContractData = {
    id: any;
    job_number: any;
    job_description: any;
}


const ContractLookup = ({ name, control, disabled, style, defaultValue, required, setValue, getValues, primary, placeholder }: any) => {
    const { field } = useController({
        name,
        control,
        defaultValue: defaultValue ?? null,
        rules: { required: required ?? false },
    });


    const client = useSupabaseClient();


    const [candidates, setCandidates] = useState<ContractData[]>([])
    const [loading, setLoading] = useState<boolean>(false);
    const [typeahead, setTypeahead] = useState<string | null>();

    const onChange: ComboboxProps["onChange"] = (event) => {
        setTypeahead(event.target.value);
        console.log(event.target.value);
        getResults(event.target.value.trim());
    }

    const debouncedSearch = React.useRef(debounce(async (criteria: any) => { await getResults(criteria) }, 0)).current;

    React.useEffect(() => { return () => { debouncedSearch.cancel(); }; }, [debouncedSearch]);

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        setTypeahead(null);
        field.onChange(candidates.find(c => c.id == data.optionValue))
    }
 


    async function search(query: string) { 
        console.log('entering search...');
        client
        .from('jobs')
        .select()
        .ilike("job_number", `%${query}%`)
        .limit(5)

        .then(({ data, error }) => { 
            console.log('data', data);
            setCandidates(data ?? []);

        }); 
    }



    const getResults = async (query: string) => {
        console.log('Fired get results');
        setLoading(true);
        await search(query);
        console.log('data');
        setLoading(false);
    }

 
    return (
        <Combobox onChange={onChange} onOptionSelect={onOptionSelect} ref={field.ref} onBlur={field.onBlur} 
            value={typeahead ? typeahead : field.value ? `${field.value?.job_number}` : ''} selectedOptions={field?.value?.id}
            disabled={disabled} style={{ minWidth: 'unset' }} appearance='filled-darker'  placeholder={"Select a contract"}
            input={{ style: { width: '100px' } }}>

            {loading && <Option disabled>Loading...</Option>}
            {!loading && candidates?.map(candidate => <Option key={candidate.id} value={candidate.id} text={`${candidate.job_number} - ${candidate.job_description}`}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Caption1Strong>{candidate.job_number}</Caption1Strong>
                    <Caption1>{candidate.job_description}</Caption1>
                </div>
            </Option>)}
         </Combobox>
    )
}

export { ContractLookup}

