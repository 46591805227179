import { TabList, Tab, TabListProps } from '@fluentui/react-components';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { StatusOptions, setStatus } from '../store/filterSlice';
import { Checkmark12Regular, Question16Filled } from '@fluentui/react-icons';

const StatusTabs = () => {
    const dispatch = useAppDispatch();
    const selectedItem = useAppSelector(s => s.filters.status);
    const onSelect: TabListProps["onTabSelect"] = (_e, { value }) => { dispatch(setStatus(value as StatusOptions)) }
    return (
        <TabList selectedValue={selectedItem} onTabSelect={onSelect} reserveSelectedTabSpace>
            <Tab value={StatusOptions.All} >All</Tab>
            <Tab value={StatusOptions.Submitted} >Pending</Tab>
            <Tab value={StatusOptions.Approved}>Approved</Tab>
        </TabList>
    )

}

export { StatusTabs }