import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import "./index.css";
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

// Sentry.init({
//     dsn: "https://b17b6d780c5d97e04d52951b4c7df782@o877444.ingest.sentry.io/4505793524465664",
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//             React.useEffect,
//             useLocation,
//             useNavigationType,
//             createRoutesFromChildren,
//             matchRoutes
//           ),
//       }),
//       new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
  


const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

 