import * as React from "react";
import { DatePicker, DateRangeType, DayOfWeek } from "@fluentui/react-datepicker-compat";
import {sub, add} from 'date-fns'; 
import { useController } from "react-hook-form";


const DatePickerTwo = ({name, control}  : any) => {
    const { field } = useController({
        name,
        control,
        rules: { required: true },
    });
    

    const onSelectDate = (date: Date | null | undefined) => 
            field.onChange(
                date != null 
                ? sub(date, {days : date.getDay() + 1}) 
                : null);

    const formatDate = (date? : Date) => {
        if (date == null) return '';
        let endDate = add(date, {days: 6});
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`

    }

    return <div>
        <DatePicker
            value={field.value} input={{style: {width: '250px'}}}
            onSelectDate={onSelectDate} 
            formatDate={formatDate}
            calendar={{
                dateRangeType: DateRangeType.Week
            }}
            firstDayOfWeek={DayOfWeek.Saturday}
        />
    </div>
}

export {DatePickerTwo}