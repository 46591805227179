import React, { useEffect } from 'react';

import { Auth } from '@supabase/auth-ui-react'
import {
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared'
import { Button, makeStyles } from '@fluentui/react-components';
import {supabase} from './supabaseClient';
 import { useSessionContext } from "@supabase/auth-helpers-react";


const useStyles = makeStyles({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center'
    },
    content: {
        width: '200px',
        height: '500px'
    }
})




const AuthFinal = () => {
    
        const styles = useStyles();
        const { session, isLoading, supabaseClient, error } = useSessionContext();

    

        useEffect(() => {
            const params = new URLSearchParams(document.location.search);
            const code = async () => {
                if(error) {
                 } else if (params.get('refresh') === 'true' && !session) {
                    await supabaseClient.auth.getSession();
                } else if (!isLoading && session?.user) {
                     console.log(session);
                 }
            }
            code();
        }, [session, isLoading, supabaseClient, error]);

  

        return <span>Done</span>
}



const SupabaseAuthPopup = () => {

    const styles = useStyles();

    const signInWithGoogle = async () => {
        const { error } = await supabase.auth.signInWithOAuth({
          provider: "azure",
          options: {
            redirectTo: `${window.location.origin}/auth-end`,
          },
        });
        if (error) alert(error.message);
      };



    return <div className={styles.root}>
        <div className={styles.content}>
            <Auth  supabaseClient={supabase} providers={['azure']}
                redirectTo='/auth-end' 
            appearance={{ theme: ThemeSupa }} onlyThirdPartyProviders  />
         </div>

    </div>
}





const AuthPage = () => {

    const styles = useStyles();


    const authInTeams = async () => {
       
    }
    return (
        <div>
            <Button onClick={authInTeams}>Open Auth</Button>

            <hr />

         </div>
    )


    
}

export {AuthPage, SupabaseAuthPopup, AuthFinal};