import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import SubmissionPage from "../pages/Submission";
import { AuthFinal, AuthPage, SupabaseAuthPopup } from "../AuthPage";
import { LoginPage } from "../LoginPage";
import { useSession, useSessionContext, useUser } from "@supabase/auth-helpers-react";
import { TestPage } from "../Test";
import { supabase } from "../supabaseClient";
import { useAppDispatch, useAppSelector } from "../hooks";
import {authHandler} from '../store/userSlice';

const AppRouter = () => {

  const session = useSession();
  const user = useUser();
 
  const { error, isLoading, } = useSessionContext();
  
  //hookup state
  const dispatch = useAppDispatch();
  const role = useAppSelector(s => s.user.name);


  supabase.auth.onAuthStateChange(async (event, session) => {
    if (session?.user.id != null) {
      const {data, error} = await supabase
      .from("profiles")
      .select("*, role(*)")
      .eq('id', session?.user?.id) 
      .maybeSingle();
      dispatch(authHandler(data?.role));
    }
  });
 
  return (
    <Router>
      {!session ? (
        <Routes>
          <Route path="/auth-dialog" element={<AuthPage />} />
          <Route path="/auth-prompt" element={<SupabaseAuthPopup />} />
          <Route path="/auth-end" element={<AuthFinal />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/tab" element={<SubmissionPage />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="*" element={<Navigate to={"/tab"} />}></Route>
        </Routes>
      )}
    </Router>
  )

}

export default AppRouter;