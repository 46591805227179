import { DayOfWeek } from "@fluentui/react-datepicker-compat";
import { isAfter } from "date-fns";
import { de } from "date-fns/locale";


type overtimeInputs = {
    start: number,
    finish: number,
    dayOfWeek: number,
    baseFriday: number,
    baseMidweek: number
}

const getOvertime = (inputs: overtimeInputs) => {
    const { start, finish, dayOfWeek, baseFriday, baseMidweek } = inputs;

    console.log('dayOfWeek', dayOfWeek);
    console.log(dayOfWeek === 0 ? "SATURDAY" : "DONE!");

    console.log('Entering OT function');
    let deduction = getBreakDeduction({
        start: start,
        finish: finish, 
        isFriday: dayOfWeek === 6
    });

    let basicInputs = {
        start : start,
        finish : finish,
        dayOfWeek : dayOfWeek,
        baseFriday : baseFriday,
        baseMidweek : baseMidweek,
        deduction : deduction
    }

    console.log(basicInputs);

    //let basicHours = getBasic(start, finish, profile, dayOfWeek) //getBreakDeduction(start, finish, dayOfWeek)) 
    
   
    console.log('O/T funciton')
    console.log(inputs)
    console.log('deduction', deduction)


    let basicHours = getBasic(basicInputs) + deduction;

    console.log("Returning value" , Math.max(0, (finish - start) - (basicHours)))
    return Math.max(0, (finish - start) - (basicHours));


}

type basicInputs = {
    start: number,
    finish: number,
    dayOfWeek: number,
    baseFriday: number,
    baseMidweek: number,
    deduction: number
}

const getBasic = (inputs: basicInputs) => {
    const { start, finish, dayOfWeek, baseFriday, baseMidweek, deduction } = inputs;
    let hoursWorked = 0;
    if (dayOfWeek === 0 || dayOfWeek === 1) {
        hoursWorked = 0; //no one gets normal time on Weekend all is overtime
    } else if (dayOfWeek === 6) {
        //special logic for Friday
        hoursWorked = Math.min((finish - start), baseFriday)
    } else {
        hoursWorked = Math.min((finish - start), baseMidweek)
    }

    //Can't be zero, but can't be more than 8, but can't be less than worked
    return Math.max(hoursWorked - deduction, 0);
}


type breakDeductionInputs = {start: number, finish: number, isFriday: boolean}


const getBreakDeduction = (inputs : breakDeductionInputs) => {
    const {start, finish, isFriday} = inputs;

    let hasImpliedBreak = (start < 12 && 12.5 < finish);

    if (isFriday && (finish - start >= 7 || hasImpliedBreak)) {
        return 0.5;
    } else if (finish - start > 8 || hasImpliedBreak) {
        return 0.5;
    } else {
        return 0;
    }
}

const getLunchDeduction = (start: number, finish: number) => Math.floor((finish - start) / 8) * 0.5;


type getSatOvertimeInputs = {
    start: number,
    finish: number,
    isAfter12: boolean
}

const getSatOvertime = (inputs: getSatOvertimeInputs) => {

    const { start, finish, isAfter12 } = inputs;
    let breakDeduct = (finish - start > 8) ? 0.5 : 0;
    
    let duration = (finish - start) - breakDeduct;
    let offsetFinish = start + duration;
 
    // |Start| ----- basic ------- |End|
    //Time occur both before 12 so return zero time;
    if ((isAfter12 && start <= 12 && finish <= 12)) return 0;
    //After 12 both
    if ((isAfter12 && start >= 12 && finish >= 12)) return finish - start - breakDeduct;
    if ((!isAfter12 && start > 12 && finish > 12)) return 0;
    if ((!isAfter12 && start <= 12 && finish <= 12)) return finish - start - breakDeduct;

    //Time occurs before and after 12
    if (isAfter12) {
        return Math.max(0, finish - 12 - breakDeduct); //time before12
    }
    if (!isAfter12) {
        return Math.max(0, 12 - start); //time before 12
    }

    return -1;
}

 
type getWeekendOvertimeInputs = { 
    start : number,
    finish : number,
    max: number,
    min: number
}

const getWeekendOvertime = (inputs : getWeekendOvertimeInputs) => {
    const {start, finish, max, min} = inputs;
    let basicHours = finish - start
    if (basicHours <= min) return 0; //i.e it's less than the minimum for this calculation
    if (basicHours >= max) return max;

    let impliedBreak = ((start < 12 && 12.5 < finish) || basicHours > 8) ? 0.5 : 0;;


    return basicHours - min - impliedBreak;
}


export { getBreakDeduction, getLunchDeduction, getBasic, getWeekendOvertime, getSatOvertime, getOvertime }
export type { overtimeInputs, basicInputs, getSatOvertimeInputs}