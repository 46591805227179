import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Badge, Body1, Button, Divider, Spinner, Title3, Tooltip } from '@fluentui/react-components';
import { DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle, DrawerOverlay } from '@fluentui/react-components/unstable';
import { Dismiss24Regular, ErrorCircle12Regular, PersonQuestionMark24Regular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import {
    Field, Textarea, Table, Caption1Strong, TableCellLayout, TableHeader,
    TableHeaderCell, TableRow, TableBody, tokens
} from '@fluentui/react-components';
import { Form, SubmitHandler, useForm } from 'react-hook-form';
import { supabase } from '../supabaseClient';
import { PeoplePicker } from './PeoplePicker';
import { add } from 'date-fns';
import DiscardModal from '../components/DiscardModal';
import { DatePickerTwo } from '../components/DatePickerTwo';
import { DayRow } from '../components/forms/DayRow';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { ErrorBoundary } from '@sentry/react';
import { DevTool } from '@hookform/devtools';
import { Contract } from './FormTypes';
import { Database } from '../types';
import { useAppSelector } from '../hooks';
import BreakTooltip from '../components/BreakTooltip';
import DeleteModal from '../components/DeleteModal';


enum ModalPresentationMode {
    New,
    Edit,
    View
}

type Inputs = {
    people?: any,
    weekStartDate?: Date,
    comment?: string, 

    mon_status: string,
    mon_start: number,
    mon_finish: number,
    mon_travel: number,
    mon_workingaway?: boolean,
    mon_subsistance?: boolean,
    mon_attendance?: boolean,

    tue_status: string,
    tue_start: number,
    tue_finish: number,
    tue_travel: number,
    tue_workingaway?: boolean,
    tue_subsistance?: boolean,
    tue_attendance?: boolean,

    wed_status: string,
    wed_start: number,
    wed_finish: number,
    wed_travel: number,
    wed_workingaway?: boolean,
    wed_subsistance?: boolean,
    wed_attendance?: boolean,

    thur_status: string,
    thur_start: number,
    thur_finish: number,
    thur_travel: number,
    thur_workingaway?: boolean,
    thur_subsistance?: boolean,
    thur_attendance?: boolean,

    fri_status: string,
    fri_start: number,
    fri_finish: number,
    fri_travel: number,
    fri_workingaway?: boolean,
    fri_subsistance?: boolean,
    fri_attendance?: boolean,

    sat_status: string,
    sat_start: number,
    sat_finish: number,
    sat_travel: number,
    sat_workingaway?: boolean,
    sat_subsistance?: boolean,
    sat_attendance?: boolean,

    sun_status: string,
    sun_start: number,
    sun_finish: number,
    sun_travel: number,
    sun_workingaway?: boolean,
    sun_subsistance?: boolean,
    //sun_attendance: boolean,

    mon_contract?: Contract,
    tue_contract?: Contract,
    wed_contract?: Contract,
    thur_contract?: Contract,
    fri_contract?: Contract,
    sat_contract?: Contract,
    sun_contract?: Contract,
};

const formDefaults: Inputs = {
     people: null,
     weekStartDate: new Date(),
     comment: '', 
     mon_status: 'work',
    mon_start: 8,
    mon_finish: 16.5,
    mon_travel: 0, 
    tue_status: 'work',
     tue_start: 8,
    tue_finish: 16.5,
    tue_travel: 0, 
    wed_status: 'work',
    wed_start: 8,
    wed_finish: 16.5,
    wed_travel: 0, 
    thur_status: 'work',
    thur_start: 8,
    thur_finish: 16.5,
    thur_travel: 0, 
    fri_status: 'work',
    fri_start: 8,
    fri_finish: 15.5,
    fri_travel: 0, 
    sat_status: 'nowork',
    sat_start: 0,
    sat_finish: 0,
    sat_travel: 0, 
    sun_status: 'nowork',
    sun_start: 0,
    sun_finish: 0,
    sun_travel: 0, 
    
}

type ModalProps = {
    submissionId?: number
    mode?: ModalPresentationMode
}


enum DialogState {
    None,
    Loaded,
    Saving,
    Loading,
    Error
}



export default NiceModal.create(({ submissionId, mode }: ModalProps) => {
    const modal = useModal();

    const client = useSupabaseClient();

    const [defaults, setDefaults] = useState<Inputs>(formDefaults);
    const [isLoading, setIsLoading] = useState<boolean>(submissionId ? true : false);
    const [dialogState, setDialogState] = useState<DialogState>(DialogState.None);


    const approveAll = useAppSelector(x => x.user.approveAll);


    const { register, handleSubmit, watch, control, reset,
        formState: { errors, isDirty, isValid, dirtyFields},
        getValues, setValue } = useForm<Inputs>({ defaultValues: defaults });


    const dismissModal = () => {
        if (isDirty) {
            NiceModal.show(DiscardModal)
                .then((resp: any) => {
                    if (resp.discard) modal.remove();
                }).catch(() => { });
        } else {
            modal.remove(); //otherwise remove the mdoal
        }
    }

    function filterObjectKeys(obj : any, keysToKeep: string[]) {
        for (let key in obj) {
          if (!keysToKeep.includes(key)) {
            delete obj[key];
          }
        }
      }


    const deleteItem = async () => { 
        NiceModal.show(DeleteModal).then(async (resp: any) => {
            if(resp.discard == true) {
                await client
                .from('entries_weekly')
                .update({
                    isDeleted: true
                })
                .eq('id', submissionId as number);
            }
        });


    }


    const onSubmit: SubmitHandler<Inputs> = (data) => {
        let myObject : any = data;

        console.log(dirtyFields);


 


        //FK Relationships 

        myObject.person_id = myObject.people?.id;
        delete myObject.people;
        //delete myObject.weekStartDate;

        myObject.mon_contract = data.mon_contract?.id;
        myObject.tue_contract = data.tue_contract?.id;
        myObject.wed_contract = data.wed_contract?.id;
        myObject.thur_contract = data.thur_contract?.id;
        myObject.fri_contract = data.fri_contract?.id;
        myObject.sat_contract = data.sat_contract?.id;
        myObject.sun_contract = data.sun_contract?.id;
        myObject.weekStartDate = data.weekStartDate?.toISOString()

         //@ts-ignore
        supabase.from('entries_weekly').insert(myObject).then(({ data, error }) => {
            console.log(error);
            console.log(data);
            if (error == null) modal.remove();
        });
    }


    useEffect(() => {
        const loadItem = async () => {
            if (submissionId !== undefined && modal.visible) {
                setDialogState(DialogState.Loading);

                console.log('loading item...');
                console.log('Load item: ' + submissionId);
 

                /** 
                const { data, error } = await client
                    .from('entries_weekly')
                    .select(`*, people (payroll_id, job_title, full_name, profile (*)) as person,
                    jobs!entries_weekly_mon_contract_fkey (*) as mon_contract,
                    tue_contract:jobs!entries_weekly_tue_contract_fkey (id,job_number, contract_manager ),
                    wed_contract:jobs!entries_weekly_wed_contract_fkey (id,job_number, contract_manager ),
                    thur_contract:jobs!entries_weekly_thur_contract_fkey (id,job_number, contract_manager ),
                    fri_contract:jobs!entries_weekly_fri_contract_fkey (id,job_number, contract_manager ),
                    `)
                    .eq('id', submissionId as number)
                    .maybeSingle();
                **/
                    const { data, error }  =  await supabase
                    .from('entries_weekly')
                    .select(`*,
                    sat_contract:jobs!entries_weekly_sat_contract_fkey (id,job_number, contract_manager ),
                    sun_contract:jobs!entries_weekly_sun_contract_fkey (id,job_number, contract_manager ),
                    mon_contract:jobs!entries_weekly_mon_contract_fkey (id,job_number, contract_manager ),
                    tue_contract:jobs!entries_weekly_tue_contract_fkey (id,job_number, contract_manager ),
                    wed_contract:jobs!entries_weekly_wed_contract_fkey (id,job_number, contract_manager ),
                    thur_contract:jobs!entries_weekly_thur_contract_fkey (id,job_number, contract_manager ),
                    fri_contract:jobs!entries_weekly_fri_contract_fkey (id,job_number, contract_manager ),
                    people (payroll_id, job_title, full_name, profile (*)) as person,
                    created_by:profiles!created_by (id, full_name)
                    `)
                    .order('id', {ascending: false})
                    .eq('id', submissionId as number)
                    .limit(5);

                    
 

                    console.log(data);
                if (error != null) {
                    setDialogState(DialogState.Error);
                    return;
                }
                if (data) {
                    console.log(data); 

                    let temp = data[0];
                    //Entering reset block
                    //@ts-ignore
                    temp.weekStartDate = new Date(temp.weekStartDate);
                    //@ts-ignore
                    reset(temp);

                    /* 

                    setValue('sat_status', data?.sat_status ?? "");
                    setValue('sun_status', data?.sun_status ?? "");
                    setValue('mon_status', data?.mon_status ?? "");
                    setValue('tue_status', data?.tue_status ?? "");
                    setValue('wed_status', data?.wed_status ?? "");
                    setValue('thur_status', data?.thur_status ?? "");
                    setValue('fri_status', data?.fri_status ?? "");
                    setValue('sat_start', data?.sat_start);
                    setValue('sun_start', data?.sun_start);
                    setValue('mon_start', data?.mon_start);
                    setValue('tue_start', data?.tue_start);
                    setValue('wed_start', data?.wed_start);
                    setValue('thur_start', data?.thur_start);
                    setValue('fri_start', data?.fri_start);
                    setValue('sat_finish', data?.sat_finish);
                    setValue('sun_finish', data?.sun_finish);
                    setValue('mon_finish', data?.mon_finish);
                    setValue('tue_finish', data?.tue_finish);
                    setValue('wed_finish', data?.wed_finish);
                    setValue('thur_finish', data?.thur_finish);
                    setValue('fri_finish', data?.fri_finish);
                    setValue('sat_travel', data?.sat_travel);
            

                    //Update person
                    setValue('person', data?.person_id?.toString() ?? '0');
                    //@ts-ignore
                    setValue('payroll_profile', data?.people?.payroll_id ?? '0');

                    setValue('comment', data?.comments ?? '')
*/
                    setDialogState(DialogState.Loaded);
                }

                setDialogState(DialogState.Loaded);
            }
        }
        loadItem();
    }, [submissionId, client, setValue, modal.visible]);



    const approve =  async () => {
        const {data, error} = await supabase
            .from('entries_weekly')
            .update({approved: true})
            .eq('id', submissionId as number);

       if(!error) modal.remove();

       if(error) alert('Please try again later');
    }


    return (
        <Form control={control}>
            <DrawerOverlay
                position="end"
                open={modal.visible}
                style={{ width: `1500px` }}
                onOpenChange={(_, { open }) => open ? modal.show() : modal.remove()}>
                <DrawerHeader>
                    <DrawerHeaderTitle action={
                        <Button
                            style={{ zIndex: 999 }}
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={dismissModal}
                        />
                    }>
                        {mode === ModalPresentationMode.New && <span>New Bonus Sheet</span>}
                        {mode !== ModalPresentationMode.New && <span>Bonus Sheet</span>}
                    </DrawerHeaderTitle>
                </DrawerHeader>
                
                
                {(dialogState !== DialogState.Loading && dialogState !== DialogState.Error) && <DrawerBody>
                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1em', height: '100%' }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', columnGap: '1.5em' }}>
                                <PeoplePicker control={control} name="people" setValue={setValue} watch={watch} />
                                <Field label="Week">
                                    <DatePickerTwo name={"weekStartDate"} control={control} />
                                </Field>
                            </div>
                        </div>


{/*
                        <Badge size="extra-large" shape='rounded' icon={<TicketDiagonal16Filled />} appearance='tint' color='success'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Caption1Strong>[Test Flow]</Caption1Strong>
                            </div>
                        </Badge>

                */}


{watch('people') == null &&  <div style={{display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <PersonQuestionMark24Regular />
                        <Body1 color={tokens.colorBrandBackgroundHover}>No person selected</Body1>
                       </div>
                </div> }


{watch('people') !== null && <>

                          <Table noNativeElements style={{ userSelect: 'none' }}>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell style={{ maxWidth: 48 }}>
                                        <TableCellLayout>
                                            <Caption1Strong>Day</Caption1Strong>
                                        </TableCellLayout>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 48 }}>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 32 }}>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 150 }}>
                                        <Caption1Strong>Contract</Caption1Strong>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 100 }}>
                                        <Caption1Strong>Start</Caption1Strong>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 100 }}>
                                        <Caption1Strong>Finish</Caption1Strong>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 125 }}>
                                        <Caption1Strong>Travel Distance</Caption1Strong>
                                    </TableHeaderCell>

                                    {/* Calculated Blocks */}
                                    <TableHeaderCell style={{ maxWidth: 75 }}>
                                        <Caption1Strong>Basic</Caption1Strong>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 75 }}>
                                        <TableCellLayout>
                                            <Caption1Strong> O/T &nbsp;
                                            </Caption1Strong>
                                            <Tooltip content="Charged at 1.5x" relationship="label">
                                                <Badge size="small" shape="rounded" color='warning'>1.5x</Badge>
                                            </Tooltip>
                                        </TableCellLayout>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 75 }}>
                                        <TableCellLayout>
                                            <Caption1Strong> O/T &nbsp;
                                            </Caption1Strong>
                                            <Tooltip content="Accured after 4 hours Saturday and all day Sunday" relationship="label">
                                                <Badge size="small" shape="rounded" color='danger'>2x</Badge>
                                            </Tooltip>
                                        </TableCellLayout>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 50 }}>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Caption1Strong>
                                            Break 
                                        </Caption1Strong>
                                        <BreakTooltip />
                                        </div>
                                    </TableHeaderCell> 
                                    <TableHeaderCell style={{ maxWidth: 50 }}>
                                        <Caption1Strong>Travel</Caption1Strong>
                                    </TableHeaderCell>
                                    <TableHeaderCell style={{ maxWidth: 200 }}>
                                        <Caption1Strong>Bonus(es)</Caption1Strong>
                                    </TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                <ErrorBoundary>
                                <DayRow index={0} day="sat" watch={watch} control={control} register={register} getValues={getValues} setValue={setValue}/>
                                <DayRow index={1} day="sun" watch={watch} control={control} register={register} getValues={getValues} setValue={setValue}/>
                                <DayRow index={2} day="mon" watch={watch} control={control} register={register} getValues={getValues} setValue={setValue}/>
                                <DayRow index={3} day="tue" watch={watch} control={control} register={register} getValues={getValues} setValue={setValue}/>
                                <DayRow index={4} day="wed" watch={watch} control={control} register={register} getValues={getValues} setValue={setValue}/>
                                <DayRow index={5} day="thur" watch={watch} control={control} register={register} getValues={getValues} setValue={setValue}/>
                                <DayRow index={6} day="fri" watch={watch} control={control} register={register} getValues={getValues} setValue={setValue}/>
                                </ErrorBoundary>
                            </TableBody>
                        </Table> 

                         

                    <Field label="Comment">
                        <Textarea {...register('comment', { required: false })} style={{ height: 100 }} />
                    </Field>
                    </>}

                    </div>

                </DrawerBody>}

 
                {dialogState === DialogState.Loading && 
                <DrawerBody>
                <div style={{
                backgroundColor: tokens.colorNeutralBackgroundAlpha2, width: '100%', height: '100%', display: 'flex', zIndex: 99, justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '2em' }}>
                        <Spinner label={'Loading...'} size='extra-small' />
                     </div>
                </div>
                </DrawerBody>}

                {dialogState === DialogState.Error && <div style={{width: '100%', height: '100%', display: 'flex', zIndex: 99, justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.5em' }}>
                        <ErrorCircle12Regular scale={5} style={{ height: 64, width: 64 }} color={tokens.colorBrandBackground} />
                        <Title3>Failed to load</Title3>
                        <Body1>Sorry we encountered an error. Please try again later</Body1>
                     </div>
                </div>}

 

                {mode !== ModalPresentationMode.View  && <DrawerFooter style={{ flexDirection: 'row', width: '100%', display: 'flex', alignContent: 'flex-end' }}>
                    <Button appearance='outline' onClick={dismissModal}>Cancel</Button>
                    {isDirty && <Button appearance='primary' disabled={!isValid} onClick={() => handleSubmit(onSubmit)()}>
                        {
                            
                            ModalPresentationMode.New ? 'Save' : isDirty ? 'Save'  : approveAll ? 'Save & Approve' : 'You should not see this'
                        }

                    </Button>}

                    {!isDirty && approveAll && <Button appearance='primary' disabled={!isValid} onClick={() => approve()}>Approve</Button>}


                    <Button appearance='primary' disabled={!isValid} onClick={() => deleteItem()}>Delete</Button>

                     
                </DrawerFooter>}


                <DevTool control={control} placement="bottom-right" />

            </DrawerOverlay>
        </Form>
    );
});

export { ModalPresentationMode }