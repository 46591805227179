import { register } from '@ebay/nice-modal-react';
import { Body1Strong, Caption1, Select, TableCell, TableCellLayout, TableRow, tokens } from '@fluentui/react-components';
import { watch } from 'fs';
import moment from 'moment';
import React, { useEffect } from 'react';
import { getBreakDeduction, getBasic, getWeekendOvertime, getSatOvertime, getOvertime } from '../../calcUtils';
import { TimeValues } from '../../dialogs/TimePicker';
import { ContractLookup } from '../sheet/ContractLookup';
import { CustomStatusPicker, CustomCheckboxChiclet } from './FormComponents';
import {add, format, getWeek} from 'date-fns';
import { overtimeInputs, basicInputs } from '../../calcUtils';
import { isWorker } from 'cluster';


type DayRowProps = {
    watch: any,
    register: any,
    control: any,
    day: string, 
    getValues: any,
    setValue: any,
    index: number,
}

function formatHoursToTime(decimalHours: number) {
    if (isNaN(decimalHours)) return '-';
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${hours}:${String(minutes).padStart(2, '0')}`;
  }

const DayRow = (props: DayRowProps) => {

    const { watch, control, register, day } = props;

    //Handy items 
    const person = watch('people');
    const date = add(new Date(watch('weekStartDate')), {days: props.index});
    const isWorking = watch(`${day}_status`) === 'work';
    const isWeekend = (props.index === 0 || props.index === 1)
    const start = watch(`${day}_start`);
    const finish = watch(`${day}_finish`);


    useEffect(() => {
        let overtime, breakTime, basic, doubleTime;

        //Saturday
        if (props.index === 0) {


        }             //Sunday 
        else if (props.index === 1) {

        } // Everything else 
        else {
            overtime = getOvertime({
                start: start,
                finish: finish,
                dayOfWeek: props.index,
                baseFriday: person?.profile?.base_friday,
                baseMidweek: person?.profile?.base_midweek
            });
     
    
            breakTime = getBreakDeduction({ 
                        start: start, finish: finish, isFriday: props.index === 6
            });

            basic = getBasic({
                start: start,
                finish: finish, 
                dayOfWeek: props.index,
                baseFriday: person?.profile?.base_friday,
                baseMidweek: person?.profile?.base_midweek,
                deduction: breakTime
            })
        }







       

 
       
        
     
        if (props.index === 0) {
            breakTime = getBreakDeduction({ 
                start: start, finish: finish, isFriday: false
            });

            overtime = getSatOvertime({
                start: start,
                finish: finish,
                isAfter12: false
            });

            doubleTime = getSatOvertime({
                start: start,
                finish: finish,
                isAfter12: true
            }); 

            console.log('Reclaculated: ', overtime, doubleTime);
        }  

        //Sunday Case
        if (props.index === 1) {
            doubleTime = getWeekendOvertime(
                {start: start, finish: finish, max: 24, min: 0}
            )
        }

        //Regular Week
       
        //Saturday


        //Sunday


        if (props.getValues(`${day}_basic`) !== basic) props.setValue(`${day}_basic`, basic);
        if (props.index !== 1 && props.getValues(`${day}_overtime`) !== overtime) props.setValue(`${day}_overtime`, overtime);
        if (props.getValues(`${day}_break`) !== breakTime) props.setValue(`${day}_break`, breakTime);
        if (props.index === 0 || props.index === 1) props.setValue(`${day}_overtimedouble`, doubleTime)

        


    }, [start, finish, person])

    useEffect(() => {
        if (!isWorking) {
            props.setValue(`${day}_basic`, undefined);
            props.setValue(`${day}_overtime`, undefined);
            props.setValue(`${day}_overtimedouble`, undefined);
            props.setValue(`${day}_break`, undefined);
            props.setValue(`${day}_travel`, undefined);
            props.setValue(`${day}_contract`, undefined);
            props.setValue(`${day}_start`, undefined);
            props.setValue(`${day}_finish`, undefined);
            props.setValue(`${day}_attendance`, false);
            props.setValue(`${day}_subsistance`, false);
        }


    }, [isWorking])
 

    return (
        <TableRow key={day}>
            <TableCell style={{ maxWidth: 48, backgroundColor: tokens.colorBrandBackground2 }}>
                <TableCellLayout>
                    <Body1Strong>{`${day && day[0].toUpperCase() + day.slice(1)}`}</Body1Strong>
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 48, backgroundColor: tokens.colorBrandBackground2 }}>
                <TableCellLayout>
                    <Caption1>
                       {date ? format(date, 'dd/MM') : '-' }
                     </Caption1>
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 32 }}>
                <TableCellLayout>
                    <CustomStatusPicker control={control} name={`${day}_status`} value="nowork" />
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 150 }}>
                <TableCellLayout>
                    <ContractLookup required={isWorking} disabled={!isWorking} style={{ maxWidth: 150 }} control={control} name={`${day}_contract`}/>
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 100 }}>
                <TableCellLayout style={{ width: 100 }}>
                     <Select disabled={!isWorking} style={{ width: 100 }} 
                         {...register(`${day}_start`, {required: isWorking, setValueAs: (value: string) => parseFloat(value) })} appearance='filled-darker'>
                        <TimeValues />
                    </Select>
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 100 }}>
                <TableCellLayout>
                    <Select disabled={!isWorking} style={{ width: 100 }}  
                        {...register(`${day}_finish`, {required: isWorking, setValueAs: (value: string) => parseFloat(value) })} appearance='filled-darker'>
                        <TimeValues />
                    </Select>
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 125 }}>
                <TableCellLayout>
                    <Select disabled={!isWorking} style={{ width: 125 }}  {...register(`${day}_travel`, {required: isWorking, setValueAs: (value: string) => parseFloat(value) })} appearance='filled-darker'>
                        <option value="0">None</option>
                        <option value="1">0-30 mi</option>
                        <option value="1.5">31-50 mi</option>
                        <option value="2">51-70 mi</option>
                        <option value="2.5">71-90 mi</option>
                        <option value="3">91-110 mi</option>
                    </Select>
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 75, backgroundColor: tokens.colorBrandBackground2 }}>
                <TableCellLayout>
                {isWorking ? <span>{formatHoursToTime(watch(`${day}_basic`))}</span> : <span>-</span>}
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 75, backgroundColor: tokens.colorBrandBackground2 }}>
                <TableCellLayout>
                    {isWorking ? <span>{formatHoursToTime(watch(`${day}_overtime`))}</span> : <span>-</span>}
                 </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 75, backgroundColor: tokens.colorBrandBackground2 }}>
                <TableCellLayout>
                    {(isWorking && (props.index === 1 || props.index === 0)) ? 
                        <span>{formatHoursToTime(watch(`${day}_overtimedouble`))}</span> : 
                        <span>-</span>}
                  </TableCellLayout>
            </TableCell>
            <TableCell style={{ maxWidth: 50, backgroundColor: tokens.colorBrandBackground2 }}>
                <TableCellLayout>
                    {isWorking ? <span>{formatHoursToTime(watch(`${day}_break`))}</span> : <span>-</span>}
                 </TableCellLayout>
                    </TableCell>
            <TableCell style={{ maxWidth: 50, backgroundColor: tokens.colorBrandBackground2 }}>
                <TableCellLayout>
                    {isWorking ? <span>{formatHoursToTime(watch(`${day}_travel`))}</span> : <span>-</span>}
                </TableCellLayout>
            </TableCell>

            <TableCell style={{ maxWidth: 200 }}>
                <TableCellLayout>
                    {isWorking && <div style={{ display: 'flex', flexDirection: 'row', columnGap: '0.25em' }}>
                        {props.index > 1 && <CustomCheckboxChiclet control={control} name={`${day}_attendance`}>Attendance</CustomCheckboxChiclet>}
                        <CustomCheckboxChiclet control={control} name={`${day}_subsistance`}>Subsistance</CustomCheckboxChiclet>
                        <CustomCheckboxChiclet control={control} name={`${day}_workingaway`}>Working Away</CustomCheckboxChiclet>
                    </div>}
                </TableCellLayout>
            </TableCell>

        </TableRow>
    )
}

export { DayRow };
export type { DayRowProps };

