import { tokens } from "@fluentui/react-components"
import { renderPersonaCell, statusChiclet, renderDialogCellWrapper, renderStatusIcons, renderBonusChiclets } from "./CellRenderers"
import { formatDate } from "../../utils"

const defaultColumns  = [
    { field: 'id', headerName: "Wk.", width: 54, rowGroup: false, pinned: 'left', editable: false, }, 
    { field: 'people', headerName: "Person", cellRenderer: renderPersonaCell,  pinned: 'left',editable: false,
    },
    { field: 'created_by.full_name', headerName: "Author", width: 150, rowGroup: false, pinned: 'left', editable: false}, 
    { headerName: 'Status', width: 150, rowGroup: false, pinned: 'left', editable: false, 
    valueGetter: (params : any) => { return { approved : params.data?.approved, rejected : params.data?.rejected}},
    //@ts-ignore 
    valueFormatter: (params : any) => `${params.data?.approved ? 'Approved' : params.data?.rejected ? 'Rejected' : 'Pending'}`,
    cellRenderer: statusChiclet},
    { valueGetter: (params : any) => { return { comment : params.data?.comment, id: params.data?.id} }, headerName: "",valueFormatter: (params: any) => params?.data?.comment, width: 100, rowGroup: false, pinned: 'left', editable:false, cellRenderer: renderDialogCellWrapper},
     {
        headerName: 'Monday',
          children: [
            {field: 'mon_status', cellRenderer: renderStatusIcons, valueGetter: (props : any) => props.data?.mon_status,
            headerName: "Status",width: 50, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
            //@ts-ignore
            { field: 'contract_mon', headerName: "Contract", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => `${params.value?.job_number || ''}`},
            { field: 'mon_start', headerName: "Start", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'mon_finish', headerName: "Finish", width: 100, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'mon_basic', headerName: "Basic", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
            { field: 'mon_overtime', headerName: "OT", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
            { field: 'mon_travel', headerName: "T/T", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
            { field: 'price', 
            valueGetter: (params: any) : any => {return {
                subsistance: params.data?.mon_attendance,
                workingAway: params.data?.mon_workingAway,
                attendance: params.data?.mon_attendance,
                //@ts-ignore
            }}, valueFormatter: (params: any)  => `${params.data?.subsistance ? 'Sub': 'None'}`,
            headerName: "Bonus(es)", width: 400, editable: false, cellRenderer: renderBonusChiclets,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
        ]
    },
    {
        headerName: 'Tuesday',
        children: [
            {field: 'tue_status', cellRenderer: renderStatusIcons, headerName: "Status",width: 50, editable: false, cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
             //@ts-ignore
             { field: 'contract_mon', headerName: "Contract", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => `${params.value?.job_number || ''}`},
            { field: 'tue_start', headerName: "Start", width: 100, editable: false, valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'tue_finish', headerName: "Finish", width: 100, editable: false, valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'tue_basic', headerName: "Basic", width: 90, editable: false, },
            { field: 'tue_overtime', headerName: "OT", width: 90, editable: false,},
            { field: 'tue_travel', headerName: "T/T", width: 90, editable: false,},
            { field: 'price', 
            valueGetter: (params: any) => {return {
                subsistance: params.data?.tue_attendance,
                workingAway: params.data?.tue_workingAway,
                attendance: params.data?.tue_attendance,
            }}, valueFormatter: (params: any) => `${params.data?.subsistance ? 'Sub': 'None'}`,
            headerName: "Bonus(es)", width: 400, editable: false, cellRenderer: renderBonusChiclets},
      
        ]
    },
    {
        headerName: 'Wednesday',
        children: [
            {field: 'wed_status', cellRenderer: renderStatusIcons, headerName: "Status",width: 50, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
             //@ts-ignore
             { field: 'contract_mon', headerName: "Contract", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => `${params.value?.job_number || ''}`},
            { field: 'wed_start', headerName: "Start", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}, valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'wed_finish', headerName: "Finish", width: 100, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => formatDate(params.value)}, 
            { field: 'wed_basic', headerName: "Basic", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
            { field: 'wed_overtime', headerName: "OT", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
            { field: 'wed_travel', headerName: "T/T", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
            { field: 'price',
            valueGetter: (params: any) => {return {
                subsistance: params.data?.wed_attendance,
                workingAway: params.data?.wed_workingAway,
                attendance: params.data?.wed_attendance,
            }}, valueFormatter: (params: any) => "VALUe",
            headerName: "Bonus(es)", width: 400, editable: false , cellRenderer: renderBonusChiclets,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
      
        ]
    },
    {
        headerName: 'Thursday',
        children: [
            {field: 'thur_status', cellRenderer: renderStatusIcons, headerName: "Status",width: 50, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
            { field: 'thur_start', headerName: "Start", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}, valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'thur_finish', headerName: "Finish", width: 100, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'thur_basic', headerName: "Basic", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , },
            { field: 'thur_overtime', headerName: "OT", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
            { field: 'thur_travel', headerName: "T/T", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
            { field: 'price',
            valueGetter: (params: any) => {return {
                subsistance: params.data?.thur_subsistance,
                workingAway: params.data?.thur_workingAway,
                attendance: params.data?.thur_attendance,
            }}, valueFormatter: (params: any) => "VALUe",
            headerName: "Bonus(es)", width: 400, editable: false, cellRenderer: renderBonusChiclets,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
      
        ]
    },
    {
        headerName: 'Friday',
        children: [
            {field: 'fri_status', cellRenderer: renderStatusIcons, headerName: "Status",width: 50, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
             //@ts-ignore
             { field: 'contract_mon', headerName: "Contract", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => `${params.value?.job_number || ''}`},
            
            { field: 'fri_start', headerName: "Start", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}, valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'fri_finish', headerName: "Finish", width: 100, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'fri_basic', headerName: "Basic", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} ,  },
            { field: 'fri_overtime', headerName: "OT", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , },
            { field: 'fri_travel', headerName: "T/T", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , },
            { field: 'price', 
            valueGetter: (params: any) => {return {
                subsistance: params.data?.fri_attendance,
                workingAway: params.data?.fri_workingAway,
                attendance: params.data?.fir_attendance,
            }}, valueFormatter: (params: any) => "VALUe",
            headerName: "Bonus(es)", width: 400, editable: false, cellRenderer: renderBonusChiclets,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
      
        ]
    },
    {
        headerName: 'Saturday',
        children: [
            {field: 'sat_status', cellRenderer: renderStatusIcons, headerName: "Status",width: 50, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
             //@ts-ignore
             { field: 'contract_mon', headerName: "Contract", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => `${params.value?.job_number || ''}`},
            
            { field: 'sat_start', headerName: "Start", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}, valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'sat_finish', headerName: "Finish", width: 100, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'sat_basic', headerName: "Basic", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} ,  },
            { field: 'sat_overtime', headerName: "OT", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , },
            { field: 'sat_travel', headerName: "T/T", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
            { field: 'price', 
            valueGetter: (params: any) => {return {
                subsistance: params.data?.sat_attendance,
                workingAway: params.data?.sat_workingAway,
                attendance: params.data?.sat_attendance,
            }}, valueFormatter: (params: any) => "VALUe",
            headerName: "Bonus(es)", width: 400, editable: false, cellRenderer: renderBonusChiclets,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
      
        ]
    },
    {
        headerName: 'Sunday',
        children: [
            {field: 'sun_status', cellRenderer: renderStatusIcons,headerName: "Status",width: 50, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}},
             //@ts-ignore
             { field: 'contract_mon', headerName: "Contract", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => `${params.value?.job_number || ''}`},
            
            { field: 'sun_start', headerName: "Start", width: 100, editable: false, disabled: true,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover}, valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'sun_finish', headerName: "Finish", width: 100, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , valueFormatter: (params: any) => formatDate(params.value)},
            { field: 'sun_basic', headerName: "Basic", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , },
            { field: 'sun_overtime', headerName: "OT", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , },
            { field: 'sun_travel', headerName: "T/T", width: 90, editable: false,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} , },
            { field: 'price', 
            valueGetter: (params: any) => {return {
                subsistance: params.data?.sun_attendance,
                workingAway: params.data?.sun_workingAway,
                attendance: params.data?.sun_attendance,
            }}, valueFormatter: (params: any) => "VALUe",
             headerName: "Bonus(es)", width: 400, editable: false, cellRenderer: renderBonusChiclets,  cellStyle: {backgroundColor: tokens.colorNeutralBackground4Hover} },
        ]
    }
]

export {defaultColumns}