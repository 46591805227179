import { Body1Strong, Button, Caption1Strong, Popover, PopoverSurface, PopoverTrigger } from "@fluentui/react-components"
import { Info12Filled } from "@fluentui/react-icons"

const BreakTooltip = () => {
    return  <Popover>
    <PopoverTrigger disableButtonEnhancement>
         <Button icon={<Info12Filled />} appearance="transparent"/>
    </PopoverTrigger>
    <PopoverSurface>
        <div style={{display: 'flex', flexDirection: 'column', width: 200}}>
            <Body1Strong>How are breaks calculated?</Body1Strong>
            <p>If someone works more that 8 hours they will 
                automatically have a 30 minute break deducted from thier basic pay.</p>
            <Caption1Strong>Implied Break</Caption1Strong>
            <p>If somework works over the 12 to 12:30 break they will automatically 
                have a 30 minute period deducted as an implied lunch break.</p>

            <Caption1Strong>At most, only one 30 minute period will be deducted</Caption1Strong>
        </div>
    </PopoverSurface>
  </Popover>
}


export default BreakTooltip