import * as React from 'react';
import { useAppDispatch } from '../../hooks';
import { Combobox, ComboboxProps, Option, OptionGroup, } from '@fluentui/react-components';
import { supabase } from '../../supabaseClient';
import {debounce} from 'lodash-es'
import { Search16Regular } from '@fluentui/react-icons';
import { add, FilterType } from '../../store/filterSlice';
import { useSupabaseClient } from '@supabase/auth-helpers-react';


const FilterComboBox = () => {

    const abortController = new AbortController();
    const dispatch = useAppDispatch();

    const [pLoading, setpLoading] = React.useState<boolean>(false);
    const [cLoading, setcLoading] = React.useState<boolean>(false);


    const client = useSupabaseClient();

 

    const contratS = async (criteria : string) => { 
        client.from('jobs').select().ilike("job_number", `%${criteria}%`).limit(5).then(({ data, error }) => { 
            console.log('data', data);
            setContracts(data ?? []);
        });
    }

    const searchP = async (criteria : string) => {
        const { data, error } = await supabase
            .from('people')
            .select('id, job_title, full_name')
            .limit(5)
            .ilike('full_name', `%${criteria}%`)
        if(data) setPeople(data)
    }

    const contratSOld = async (criteria : string) => {
        setcLoading(true);
        const { data, error } = await supabase
            .from('jobs')
            .select('id, job_number')
            .limit(5)
            .ilike('job_number', `%${criteria}%`)
         if(data) setContracts(data)
        setcLoading(false);
    }
    const pSearch = async (criteria : string) => {
         setpLoading(true);
        const { data, error } = await supabase
            .from('people')
            .select('id, job_title, full_name')
            .limit(5)
            .ilike('full_name', `%${criteria}%`)
          if(data) setPeople(data)
        setpLoading(false);
    }

    const peopleSearch = React.useRef(debounce(async (criteria : any) => {await pSearch(criteria)}, 100)).current;
    const contractSearch = React.useRef(debounce(async (criteria: any) => {await contratS(criteria )}, 100)).current;


    //Cancel outgoing requests on clean up
    React.useEffect(() => { return () => { abortController.abort(); peopleSearch.cancel()}}, [peopleSearch]);
    React.useEffect(() => {  return () => {  contractSearch.cancel()}}, [contractSearch]);


    const [people, setPeople] = React.useState<any[]>([]);
    const [contracts, setContracts] = React.useState<any[]>([]);

    const onChange: ComboboxProps["onChange"] = (event) => {
        const value = event.target.value.trim();
        contractSearch(value)
        peopleSearch(value)
    };

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, {optionText, optionValue, selectedOptions}) => {
        
        if (optionValue && optionText) {
            if (optionValue.startsWith('p-')) { 
                dispatch(add({
                    type: FilterType.Person, 
                    value: optionValue.replace('p-', ''), 
                    display: optionText, key: `${optionText}-${optionValue}`
                }))
            }
            if (optionValue.startsWith('c-')) { 
                dispatch(add({
                    type: FilterType.Contract, 
                    value: optionValue.replace('c-', ''), 
                    display: optionText, 
                    key: `${optionText}-${optionValue}`
                }))
            }
        }   
    }
    
    return (
        <Combobox onChange={onChange} onOptionSelect={onOptionSelect} expandIcon={<Search16Regular />} multiselect={false} >
            <OptionGroup label="Contracts">
                {cLoading && <Option disabled>Loading</Option>}
                {contracts && contracts.map((x) => <Option  value={'c-'+x.id} key={`${x.id}-${x.job_number}`}>{x.job_number}</Option>)}
            </OptionGroup>
            <OptionGroup label="People">
                {pLoading && <Option disabled>Loading</Option>}
                {people && people.map((x) => <Option value={'p-'+x.id} key={`${x.id}-${x.full_name}`}>{x.full_name}</Option>)}
            </OptionGroup>
        </Combobox>
    )
}

export { FilterComboBox }

 