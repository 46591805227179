import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog, DialogSurface, DialogBody, DialogTitle, 
  DialogTrigger, DialogContent, Button, DialogActions } from "@fluentui/react-components";
 
const DeleteModal =  NiceModal.create(() => {
    const modal = useModal();

    const resolve = (discard: boolean) => { 
        modal.resolve({discard});
        modal.remove();
    }

    return <Dialog open={modal.visible}>
       <DialogSurface>
        <DialogBody>
          <DialogTitle>Delete item?</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this? You cannot undo this action
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={() => resolve(false)}>Cancel</Button>
            </DialogTrigger>
            <Button appearance="primary" onClick={() => resolve(true)}>Delete</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
   </Dialog>
});


export default DeleteModal;