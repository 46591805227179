import * as React from 'react';
import { Caption1, Card, CardHeader, Spinner, makeStyles, tokens } from '@fluentui/react-components';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { Auth } from '@supabase/auth-ui-react';
import { supabase, ThemeSupa } from '@supabase/auth-ui-shared';

const useStyles = makeStyles({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#455663'
    }, 
    appLogin : {
         boxShadow: tokens.shadow28,
         paddingLeft: tokens.spacingHorizontalXXXL,
         paddingRight: tokens.spacingHorizontalXXXL,
         paddingTop: tokens.spacingHorizontalXXXL,
         paddingBottom: tokens.spacingHorizontalXXXL,
         rowGap: '2em'
     }
})

const LoginPage = () => {
    const client = useSupabaseClient();

    const styles = useStyles();

    return <div className={styles.root}>
             <Card className={styles.appLogin} size='large' >
                <CardHeader>
                    <Caption1>Bonus Sheets</Caption1>
                </CardHeader>
                <img src="https://www.mackenzieconstruction.com/wp-content/themes/website/assets/images/mackenzie-construction-logo.svg" alt="Mackenzie Construction" width={250} /> 
                <Auth  supabaseClient={client} providers={['azure']}
                redirectTo='/auth-end' 
            appearance={{ theme: ThemeSupa }} onlyThirdPartyProviders  />
            </Card>
            </div>
 }

export {LoginPage}