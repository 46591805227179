import { CalendarMonthRegular } from '@fluentui/react-icons';
import { Tag, TagGroup, TagGroupProps,} from '@fluentui/react-tags-preview';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Button } from '@fluentui/react-components';
import { FilterType, add, remove } from '../../store/filterSlice';


const FilterChiclets = () => {
    //Provide typed dispatch kind
    const counter = useAppSelector(s => s.filters.filters)
    const dispatch = useAppDispatch();

    const removeItem: TagGroupProps["onDismiss"] = ( _e, { dismissedTagValue } ) => dispatch(remove(dismissedTagValue));
 
    return (
        <TagGroup onDismiss={removeItem}>
            {counter && counter.map((option) => 
                <Tag icon={<CalendarMonthRegular/>} value={option.key} dismissible size='small'>{option.display}</Tag>
            )}
        </TagGroup>
    )
}

export { FilterChiclets }